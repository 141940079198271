import React, { FC, useRef } from "react";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";
import { Link } from "gatsby";
import "../../pages/academy-tumar.css";
import Books from "../../images/books.webm";
import { cmsImageLoader } from "../../utils/cmsImageLoader";

type PromotionItemProps = {
  intl: any;
  title: string;
  subtitle: string;
  description: string;
  button: any;
  img: string;
  id: number;
  sharedId: number;
};

// @ts-ignore
const AcademyTumarItem: FC<PromotionItemProps> = ({
  intl,
  title,
  subtitle,
  button,
  img,
  id,
  sharedId,
}) => {
  return (
    <div className="rounded-[1rem] mt-[2rem] w-auto h-auto grid lg:grid-flow-col lg:grid-cols-none sm:grid-cols-2 sm:justify-start lg:gap-[2rem] sm:gap-[1rem] bg-white border border-opacity-25 border-[#618EE4]">
      <div className="cursor-pointer flex items-center justify-center justify-start xl:w-[24rem] lg:w-[20.25rem] sm:w-[auto] h-[100%] xs:w-[18.6rem] xs:h-[14rem] sm:h-[18rem] rounded-tl-[1rem] xs:rounded-tr-[1rem] sm:rounded-tr-[0rem]  sm:rounded-bl-[1rem]">
        <img src={cmsImageLoader({ src: img })} className="h-full"></img>
      </div>
      <div className="grid py-[1.5rem] pr-[2rem] xs:pl-[0.5rem]">
        <h2 className="lg:text-[1.75rem] sm:text-[1.6rem] font-semibold">
          {title}
        </h2>
        <p className="lg:text-[1.25rem] sm:text-[1.3rem] font-medium">
          {subtitle}
        </p>
        <Link to={`/academy-tumar-detail?id=${sharedId}`}>
          <button className="bg-[#618EE4] mt-[1rem] text-[1.25rem] text-white font-semibold  rounded-[0.56rem] lg:w-[12rem] xs:w-[9.6rem] h-[3rem] flex justify-center items-center">
            {intl.formatMessage({ id: button })}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default injectIntl(AcademyTumarItem);
