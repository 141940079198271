import { gql } from "@apollo/client";

export const GET_COURSES = gql`
  query Courses($pagination: PaginationArg, $locale: I18NLocaleCode) {
    courses(pagination: $pagination, locale: $locale, sort: "priority:asc") {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          sharedId
          title
          description
          body
          image {
            data {
              attributes {
                url
              }
            }
          }
          bannerLaptop {
            data {
              attributes {
                url
              }
            }
          }
          bannerTabletHorizontal {
            data {
              attributes {
                url
              }
            }
          }
          bannerTabletVertical {
            data {
              attributes {
                url
              }
            }
          }
          bannerPhone {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
