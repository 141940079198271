import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import ArrowLeft from "../images/arrow_left_blue.svg";
import ArrowRight from "../images/arrow_right_blue.svg";
import { useEffect } from "react";

// @ts-ignore
const Pagination = ({ intl, setPage, pagination }) => {
  const handleOnClickPage = (pageNumber: any) => {
    setPage(pageNumber);
  };

  const handleOnClickPrev = () => {
    setPage(Math.max(pagination.page - 1, 0));
  };

  const handleOnClickNext = () => {
    setPage(Math.min(pagination.page + 1, pagination.pageCount));
  };

  // 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30
  let rows = [];
  let firstPage = 1;
  let lastPage = 1;
  if (pagination && pagination.pageCount > 1) {
    firstPage = Math.max(firstPage, pagination.page - 2);
    lastPage = Math.min(pagination.pageCount, firstPage + 4);
    for (let i = firstPage; i <= lastPage; i++) {
      rows.push(
        <div
          className={
            i === pagination.page
              ? " bg-[#618EE4] text-white flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer"
              : "text-[#618EE4] flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer "
          }
          onClick={() => handleOnClickPage(i)}
          key={`page-${i}`}
        >
          <h4 className="font-semibold text-[1.25rem]">{i}</h4>
        </div>
      );
    }
  }

  if (pagination) {
    return pagination.pageCount > 1 ? (
      <div className="flex justify-end sm:gap-[1rem] xs:gap-[0.5rem] mt-[2rem]">
        <div
          className="flex justify-center cursor-pointer"
          onClick={handleOnClickPrev}
        >
          <img src={ArrowLeft} alt="" className="w-[2rem] h-[2rem]" />
        </div>
        {firstPage !== 1 ? (
          <div
            className="text-[#618EE4] flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer "
            onClick={() => handleOnClickPage(1)}
            key="page-1"
          >
            <h4 className="font-semibold text-[1.25rem]">1</h4>
          </div>
        ) : null}
        {firstPage !== 1 ? "..." : null}
        {rows}
        {lastPage !== pagination.pageCount ? "..." : null}
        {lastPage !== pagination.pageCount ? (
          <div
            className="text-[#618EE4] flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer "
            onClick={() => handleOnClickPage(pagination.pageCount)}
            key={`page-${pagination.pageCount}`}
          >
            <h4 className="font-semibold text-[1.25rem]">
              {pagination.pageCount}
            </h4>
          </div>
        ) : null}
        <div
          className="flex justify-center cursor-pointer"
          onClick={handleOnClickNext}
        >
          <img src={ArrowRight} alt="" className="w-[2rem] h-[2rem]" />
        </div>
      </div>
    ) : null;
  }

  return (
    <div className="flex justify-end sm:gap-[1rem] xs:gap-[0.5rem] mt-[2rem]">
      <div className="flex justify-center cursor-pointer">
        <img src={ArrowLeft} alt="" className="w-[2rem] h-[2rem]" />
      </div>
      <div className=" bg-[#618EE4] text-white flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer">
        <h4 className="font-semibold text-[1.25rem]">1</h4>
      </div>
      <div className="text-[#618EE4] flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer ">
        <h4 className="font-semibold text-[1.25rem]">2</h4>
      </div>
      <div className="text-[#618EE4] flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer ">
        <h4 className="font-semibold text-[1.25rem]">3</h4>
      </div>
      <div className="text-[#618EE4] flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer ">
        <h4 className="font-semibold text-[1.25rem]">4</h4>
      </div>
      <div className="text-[#618EE4] flex justify-center items-end w-[2rem] h-[1.5rem] rounded-[0.375rem] cursor-pointer">
        <h4 className="flex font-semibold text-[1.25rem] gap-[0.2rem]">
          <span className="w-[0.25rem] h-[0.25rem] rounded-[50%] bg-[#618EE4] inline-block"></span>
          <span className="w-[0.25rem] h-[0.25rem] rounded-[50%] bg-[#618EE4] inline-block"></span>
          <span className="w-[0.25rem] h-[0.25rem] rounded-[50%] bg-[#618EE4] inline-block"></span>
        </h4>
      </div>
      <div className="text-[#618EE4] flex justify-center items-center w-[2rem] h-[2rem] rounded-[0.375rem] cursor-pointer ">
        <h4 className="font-semibold text-[1.25rem]">10</h4>
      </div>
      <div className="flex justify-center cursor-pointer">
        <img src={ArrowRight} alt="" className="w-[2rem] h-[2rem]" />
      </div>
    </div>
  );
};

export default injectIntl(Pagination);
