//@ts-ignore
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";
import { PageContext } from "gatsby/internal";

type BreadCrumbsProps = {
  //@ts-ignore
  intl: injectIntl;
  pageContext: PageContext;
  title: string;
};

const BreadCrumbs: FC<BreadCrumbsProps> = ({ intl, pageContext, title }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const intlCrumbs = crumbs.map((item, index) =>
    index === 0
      ? {
          pathname: "/",
          crumbLabel: parse(
            intl.formatMessage({
              id: "BreadCrumbRoot",
            })
          ),
        }
      : item
  );

  return (
    <Breadcrumb
      crumbs={intlCrumbs}
      crumbSeparator=">"
      hiddenCrumbs={["/ru", "/kk", "/en"]}
      crumbLabel={parse(
        intl.formatMessage({
          id: title,
        })
      )}
    />
  );
};

export default injectIntl(BreadCrumbs);
