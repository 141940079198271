import * as React from "react";
import Layout from "../components/Layout/Layout";
import parse from "html-react-parser";
import AcademyTumarItem from "../components/academyTumar/AcademyTumarItem";
import Pagination from "../components/Pagination";
import "./academy-tumar.css";
import { injectIntl } from "gatsby-plugin-intl";
import BreadCrumbs from "../BreadCrumbs";
import InProcess from "../components/inProcess/InProcess";
import { useQuery } from "@apollo/client";
import { GET_COURSES } from "../query/getCourses";

//@ts-ignore
const academyTumar = ({ intl, pageContext }) => {
  const [page, setPage] = React.useState(0);
  let locale;
  const pageSize = 10;
  const { data, loading } = useQuery(GET_COURSES, {
    variables: {
      locale: intl.locale,
      pagination: { page, pageSize },
    },
    fetchPolicy: "network-only",
  });

  return (
    <Layout>
      <>
        {/* <InProcess /> */}
        <div className="grid xs:justify-center  sm:pl-0   bg-[#FAFAFA] pb-[10rem]">
          <div className=" flex flex-col sm:grid-rows-2 xs:grid-rows-3 justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
            <div className="mt-[2.5rem] mb-[2.5rem]">
              <BreadCrumbs
                pageContext={pageContext}
                title="AcademyTumar.Title"
              />
            </div>
            <h2 className="text-[2rem] font-semibold leading-5">
              {parse(
                intl.formatMessage({
                  id: "AcademyTumar.Title",
                })
              )}
            </h2>
            <p className="mt-[2rem] font-medium text-[1rem]">
              {parse(
                intl.formatMessage({
                  id: "AcademyTumar.Description",
                })
              )}
            </p>

            {data?.courses?.data?.map((course: any) => (
              <AcademyTumarItem
                //@ts-ignore
                intl={intl}
                title={course.attributes.title}
                subtitle={course.attributes.description}
                button={"AcademyTumar.More"}
                img={course?.attributes?.image?.data?.attributes?.url}
                id={course?.id}
                sharedId={course?.attributes?.sharedId}
              />
            ))}

            <Pagination
              setPage={setPage}
              pagination={data?.courses?.meta?.pagination || {}}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};

export default injectIntl(academyTumar);
